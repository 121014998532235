/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum RefundReasonEnum {
    NOT_SATISFIED = 'not_satisfied',
    ACCIDENTAL_PURCHASE = 'accidental_purchase',
    TECHNICAL_ISSUES = 'technical_issues',
    EXPECTATIONS_NOT_MET = 'expectations_not_met',
    OTHER = 'other',
}
