import { createContext, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ConfirmProvider } from 'material-ui-confirm';
import { CurrentUserAndStudentResponse, UserType } from '../client';
import { PyodideProvider } from '../context/PyodideContext';
import { CodeProvider } from '../context/CodeContext';
import { GenericModalProvider } from '../context/GenericModalContext';
import { ApiLevelDataProvider } from '../context/ApiLevelDataContext';
import { OutputProvider } from '../context/OutputContext';
import { TipProvider } from '../context/TipProvider';
import { LocaleProvider } from '../context/LocaleContext';
import { VirtualAssistantProvider } from '../context/VirtualAssistantContext';
import { LevelsProvider } from '../context/LevelsContext';
import { SnackBarProvider } from '../context/SnackBarContext';

import { LocaleRedirect } from '../components/LocaleSelect/LocaleRedirect';
import { useCurrentUser } from '../hooks/useCurrentUser';

import Layout from './Layout';
import { Loader } from '../components/Loader';
import { Box } from '@mui/material';
import Tracker from '@openreplay/tracker';
import RefundPolicy from './RefundPolicy';
import useSetUTMSource from '../hooks/useSetUTMSource';

const AddEditClass = lazy(() => import('./organization/Classes/AddEditClass'));
const CertificateViewer = lazy(() => import('../components/Certificate/CertificateViewer'));
const Challenge = lazy(() => import('./Challenge'));
const Challenges = lazy(() => import('./Challenges'));
const Checkout = lazy(() => import('./Checkout'));
const ClassPage = lazy(() => import('./organization/Classes/ClassPage'));
const Classes = lazy(() => import('./organization/Classes/Classes'));
const Courses = lazy(() => import('./Courses'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const Home = lazy(() => import('./Home'));
const Impersonate = lazy(() => import('./Impersonate'));
const InstitutionalPurchase = lazy(() => import('./InstitutionalPurchase'));
const Login = lazy(() => import('./Login'));
const LoginOrg = lazy(() => import('./LoginOrg'));
const MyBadges = lazy(() => import('./MyBadges'));
const MyCertificates = lazy(() => import('./MyCertificates'));
const OrganizationAddEditStudentUser = lazy(
  () => import('./organization/Student/AddEditStudentUser')
);
const OrganizationAddEditTeacherUser = lazy(() => import('./organization/AddEditTeacher'));
const OrganizationDetails = lazy(() => import('./organization/OrganizationDetails'));
const OrganizationStudentUsers = lazy(() => import('./organization/Student/Students'));
const OrganizationTeacherUsers = lazy(() => import('./organization/Teachers'));
const Profile = lazy(() => import('./profile/Profile'));
const ProfileAddEditStudentUser = lazy(() => import('./profile/AddEditStudentUser'));
const ProfileBilling = lazy(() => import('./profile/ProfileBilling'));
const ProfileDetails = lazy(() => import('./profile/ProfileDetails'));
const ProfileStudentUsers = lazy(() => import('./profile/StudentUsers'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const ProtectedRoute = lazy(() => import('../components/ProtectedRoute'));
const Purchase = lazy(() => import('./Purchase'));
const QuizPage = lazy(() => import('./quiz/QuizPage'));
const Question = lazy(() => import('./quiz/Question'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const SignUp = lazy(() => import('./SignUp'));
const StudentPage = lazy(() => import('./profile/StudentPage'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const Unsubscribed = lazy(() => import('./Unsubscribed'));

export const UserContext = createContext<CurrentUserAndStudentResponse | undefined>(undefined);

// TODO: fix this by using strings as enums
export const UserTypeMap = {
  [UserType._0]: 'parent',
  [UserType._1]: 'teacher',
  [UserType._2]: 'admin'
};

export const tracker = new Tracker({
  projectKey: 'L1qSgoQqk4wFo5eNYTwv',
  ingestPoint: 'https://openreplay.learnx.org/ingest'
});

if (process.env.NODE_ENV === 'production') {
  tracker.start();
}

function App() {
  const { currentUser } = useCurrentUser();

  useSetUTMSource();

  return (
    <LocaleProvider>
      <UserContext.Provider value={currentUser}>
        <SnackBarProvider>
          <ApiLevelDataProvider>
            <OutputProvider>
              <CodeProvider>
                <ConfirmProvider>
                  <GenericModalProvider>
                    <TipProvider>
                      <Suspense
                        fallback={
                          <Box
                            component="div"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100vw',
                              height: '100vh'
                            }}
                          >
                            <Loader />
                          </Box>
                        }
                      >
                        <Routes>
                          <Route path="*" element={<LocaleRedirect />} />
                          <Route path="/:lng" element={<Layout />}>
                            <Route path="badges/:studentId" element={<MyBadges />} />
                            <Route path="certificates/:studentId" element={<MyCertificates />} />
                            <Route
                              path="certificate/:certificateId"
                              element={<CertificateViewer />}
                            />
                            <Route element={<ProtectedRoute />}>
                              <Route path="profile" element={<Profile />}>
                                <Route path="details" element={<ProfileDetails />} />
                                <Route path="students" element={<ProfileStudentUsers />} />
                                <Route
                                  path="students/add"
                                  element={<ProfileAddEditStudentUser />}
                                />
                                <Route
                                  path="students/:studentId/edit"
                                  element={<ProfileAddEditStudentUser />}
                                />
                                <Route path="students/:studentId" element={<StudentPage />} />
                                <Route
                                  path="student/:studentId"
                                  element={<ProfileAddEditStudentUser />}
                                />
                                <Route path="billing" element={<ProfileBilling />} />
                              </Route>
                              <Route path="organization" element={<Profile />}>
                                <Route path="details" element={<OrganizationDetails />} />
                                <Route path="teachers" element={<OrganizationTeacherUsers />} />
                                <Route
                                  path="teachers/add"
                                  element={<OrganizationAddEditTeacherUser />}
                                />
                                <Route
                                  path="teachers/:teacherId/edit"
                                  element={<OrganizationAddEditTeacherUser />}
                                />
                                <Route path="classes" element={<Classes />} />
                                <Route path="classes/add" element={<AddEditClass />} />
                                <Route path="classes/:classId" element={<ClassPage />} />
                                <Route path="classes/:classId/edit" element={<AddEditClass />} />
                                <Route path="students" element={<OrganizationStudentUsers />} />
                                <Route
                                  path="students/add"
                                  element={<OrganizationAddEditStudentUser />}
                                />
                                <Route
                                  path="students/:studentId/edit"
                                  element={<OrganizationAddEditStudentUser />}
                                />
                                <Route path="students/:studentId" element={<StudentPage />} />
                              </Route>
                              <Route path="quiz/:courseId/:chapterId" element={<QuizPage />}>
                                <Route path="question/:questionId" element={<Question />} />
                              </Route>
                            </Route>
                            <Route
                              index
                              element={
                                <LevelsProvider>
                                  <VirtualAssistantProvider>
                                    <PyodideProvider>
                                      <Home />
                                    </PyodideProvider>
                                  </VirtualAssistantProvider>
                                </LevelsProvider>
                              }
                            />
                            <Route path="unsubscribed" element={<Unsubscribed />} />
                            <Route path="tos" element={<TermsOfService />} />
                            <Route path="privacy" element={<PrivacyPolicy />} />
                            <Route path="refund-policy" element={<RefundPolicy />} />
                            <Route path="checkout" element={<Checkout />} />
                            <Route path="purchase" element={<Purchase />} />
                            <Route path="courses" element={<Courses />} />
                            <Route path="schools" element={<InstitutionalPurchase />} />
                            <Route path="login" element={<Login />} />
                            <Route path="login-org" element={<LoginOrg />} />
                            <Route path="login-org/:organizationSlug" element={<LoginOrg />} />
                            <Route path="signup" element={<SignUp />} />
                            <Route path="forgot-password" element={<ForgotPassword />} />
                            <Route path="reset-password" element={<ResetPassword />} />
                            <Route path="impersonate" element={<Impersonate />} />
                            <Route
                              path="challenges"
                              element={
                                <PyodideProvider>
                                  <Challenges />
                                </PyodideProvider>
                              }
                            />
                            <Route
                              path="challenge/:challengeSlug"
                              element={
                                <VirtualAssistantProvider>
                                  <PyodideProvider>
                                    <Challenge />
                                  </PyodideProvider>
                                </VirtualAssistantProvider>
                              }
                            />
                            <Route
                              path="play/:courseSlug/:levelSlug"
                              element={
                                <LevelsProvider>
                                  <VirtualAssistantProvider>
                                    <PyodideProvider>
                                      <Home />
                                    </PyodideProvider>
                                  </VirtualAssistantProvider>
                                </LevelsProvider>
                              }
                            />
                          </Route>
                        </Routes>
                      </Suspense>
                    </TipProvider>
                  </GenericModalProvider>
                </ConfirmProvider>
              </CodeProvider>
            </OutputProvider>
          </ApiLevelDataProvider>
        </SnackBarProvider>
      </UserContext.Provider>
    </LocaleProvider>
  );
}

export default App;
