/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { Student } from '../models/Student';
import type { TokenData } from '../models/TokenData';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Queue User Emails
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static queueUserEmailsApiUserQueueEmailsPost(
        requestBody: TokenData,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/queue_emails',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Queue User Emails For All
     * @param templateName
     * @param subject
     * @param requestBody
     * @param recipientEmail
     * @returns any Successful Response
     * @throws ApiError
     */
    public static queueUserEmailsForAllApiUserQueueUserEmailsForAllPost(
        templateName: string,
        subject: string,
        requestBody: TokenData,
        recipientEmail?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/queue_user_emails_for_all',
            query: {
                'template_name': templateName,
                'subject': subject,
                'recipient_email': recipientEmail,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send User Email From Queue
     * @param rows
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendUserEmailFromQueueApiUserSendEmailsPost(
        rows: number,
        requestBody: TokenData,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/send_emails',
            query: {
                'rows': rows,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Email Opened
     * @param emailId
     * @param redirectUrl
     * @returns any Successful Response
     * @throws ApiError
     */
    public static markEmailOpenedApiUserOpenEmailIdGet(
        emailId: string,
        redirectUrl?: (string | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/open/{email_id}',
            path: {
                'email_id': emailId,
            },
            query: {
                'redirect_url': redirectUrl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Email Unsubscribed
     * @param emailId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static markEmailUnsubscribedApiUserUnsubscribeEmailIdGet(
        emailId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/unsubscribe/{email_id}',
            path: {
                'email_id': emailId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Organizations
     * @param query
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static searchOrganizationsApiUserSearchOrganizationsGet(
        query: string,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/organizations',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Emails
     * @param query
     * @param organizationId
     * @returns User Successful Response
     * @throws ApiError
     */
    public static searchEmailsApiUserSearchEmailsGet(
        query: string,
        organizationId?: (string | null),
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/emails',
            query: {
                'query': query,
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Student Usernames
     * @param query
     * @param organizationId
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static searchStudentUsernamesApiUserSearchStudentUsernamesGet(
        query: string,
        organizationId?: (string | null),
    ): CancelablePromise<Array<Student>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/student_usernames',
            query: {
                'query': query,
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Language
     * @param language
     * @returns any Successful Response
     * @throws ApiError
     */
    public static changeLanguageApiUserChangeLanguagePost(
        language: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/change-language',
            query: {
                'language': language,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Language
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLanguageApiUserGetLanguageGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/get-language',
        });
    }

}
