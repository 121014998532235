/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelSubscriptionFeedbackSchema } from '../models/CancelSubscriptionFeedbackSchema';
import type { FeedbackSchema } from '../models/FeedbackSchema';
import type { RefundFeedbackSchema } from '../models/RefundFeedbackSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FeedbackService {

    /**
     * Send Feedback
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendFeedbackApiFeedbackPost(
        requestBody: FeedbackSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/feedback',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Cancel Subscription Feedback
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendCancelSubscriptionFeedbackApiFeedbackCancelSubscriptionPost(
        requestBody: CancelSubscriptionFeedbackSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/feedback/cancel-subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Refund Feedback
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendRefundFeedbackApiFeedbackRefundPost(
        requestBody: RefundFeedbackSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/feedback/refund',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
