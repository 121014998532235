/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileUpdateForm } from '../models/ProfileUpdateForm';
import type { Student } from '../models/Student';
import type { StudentCreateForm } from '../models/StudentCreateForm';
import type { StudentListItem } from '../models/StudentListItem';
import type { StudentWithCourses } from '../models/StudentWithCourses';
import type { StudentWithPurchasedCourse } from '../models/StudentWithPurchasedCourse';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {

    /**
     * Get Profile
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getProfileApiProfileGet(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile',
        });
    }

    /**
     * Update Profile
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public static updateProfileApiProfilePost(
        requestBody: ProfileUpdateForm,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Profile
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteProfileApiProfileDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/profile',
        });
    }

    /**
     * Get Students By User
     * @returns StudentListItem Successful Response
     * @throws ApiError
     */
    public static getStudentsByUserApiProfileStudentsGet(): CancelablePromise<Array<StudentListItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/students',
        });
    }

    /**
     * Create Student
     * @param requestBody
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static createStudentApiProfileStudentsPost(
        requestBody: StudentCreateForm,
    ): CancelablePromise<Student> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/profile/students',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Student
     * @param studentId
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static getStudentApiProfileStudentsStudentIdGet(
        studentId: string,
    ): CancelablePromise<Student> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Student
     * @param studentId
     * @param requestBody
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static updateStudentApiProfileStudentsStudentIdPut(
        studentId: string,
        requestBody: StudentCreateForm,
    ): CancelablePromise<Student> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/profile/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Student
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteStudentApiProfileStudentsStudentIdDelete(
        studentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/profile/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Student Username Exists
     * @param studentUsername
     * @param organizationId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkStudentUsernameExistsApiProfileCheckStudentUsernameExistsGet(
        studentUsername: string,
        organizationId?: (string | null),
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/check-student-username-exists',
            query: {
                'student_username': studentUsername,
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Students Limit Per User
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkStudentsLimitPerUserApiProfileCheckStudentsLimitGet(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/check-students-limit',
        });
    }

    /**
     * Get Students By Purchased Course
     * @param courseSlug
     * @returns StudentWithPurchasedCourse Successful Response
     * @throws ApiError
     */
    public static getStudentsByPurchasedCourseApiProfileCourseSlugStudentsPurchasedGet(
        courseSlug: string,
    ): CancelablePromise<Array<StudentWithPurchasedCourse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/{course_slug}/students/purchased',
            path: {
                'course_slug': courseSlug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get All Students Purchased Courses
     * @returns StudentWithCourses Successful Response
     * @throws ApiError
     */
    public static getAllStudentsPurchasedCoursesApiProfileStudentsPurchasesAllGet(): CancelablePromise<Array<StudentWithCourses>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/students/purchases/all',
        });
    }

    /**
     * Get Students Without Purchased Course
     * @param courseSlug
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static getStudentsWithoutPurchasedCourseApiProfileCourseSlugStudentsNotPurchasedGet(
        courseSlug: string,
    ): CancelablePromise<Array<Student>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/profile/{course_slug}/students/not-purchased',
            path: {
                'course_slug': courseSlug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
