/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PurchaseRead } from '../models/PurchaseRead';
import type { RefundReasonEnum } from '../models/RefundReasonEnum';
import type { Webhook } from '../models/Webhook';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Paddle
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static paddleApiPaymentPaddlePost(
        requestBody: Webhook,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/paddle',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Has Unassigned Purchases
     * @returns any Successful Response
     * @throws ApiError
     */
    public static hasUnassignedPurchasesApiPaymentHasUnassignedPurchasesGet(): CancelablePromise<(string | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/has-unassigned-purchases',
        });
    }

    /**
     * Assign Purchase
     * @param studentId
     * @param courseName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static assignPurchaseApiPaymentAssignPurchasePost(
        studentId: string,
        courseName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/assign-purchase',
            query: {
                'student_id': studentId,
                'course_name': courseName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentSubscriptionApiPaymentCurrentSubscriptionGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/current-subscription',
        });
    }

    /**
     * Active Subscriptions
     * @returns PurchaseRead Successful Response
     * @throws ApiError
     */
    public static activeSubscriptionsApiPaymentActiveSubscriptionsGet(): CancelablePromise<Array<PurchaseRead>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/active-subscriptions',
        });
    }

    /**
     * Cancel Subscription
     * @param purchaseId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelSubscriptionApiPaymentCancelSubscriptionPost(
        purchaseId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/cancel-subscription',
            query: {
                'purchase_id': purchaseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Plan
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentPlanApiPaymentCurrentPlanGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/current-plan',
        });
    }

    /**
     * Upgrade Subscription
     * @param planName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static upgradeSubscriptionApiPaymentUpgradeSubscriptionPost(
        planName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/upgrade-subscription',
            query: {
                'plan_name': planName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preview Subscription Upgrade
     * @param planName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static previewSubscriptionUpgradeApiPaymentPreviewSubscriptionUpgradeGet(
        planName: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/preview-subscription-upgrade',
            query: {
                'plan_name': planName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upgrade To Organization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static upgradeToOrganizationApiPaymentUpgradeToOrganizationGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/upgrade-to-organization',
        });
    }

    /**
     * Transfer Purchases
     * @param toEmail
     * @param fromEmail
     * @param includeAssigned
     * @returns any Successful Response
     * @throws ApiError
     */
    public static transferPurchasesApiPaymentTransferPurchasesGet(
        toEmail: string,
        fromEmail?: (string | null),
        includeAssigned?: (boolean | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/transfer-purchases',
            query: {
                'to_email': toEmail,
                'from_email': fromEmail,
                'include_assigned': includeAssigned,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Refund
     * @param purchaseId
     * @param reason
     * @returns any Successful Response
     * @throws ApiError
     */
    public static requestRefundApiPaymentRequestRefundPost(
        purchaseId: string,
        reason: RefundReasonEnum,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/request-refund',
            query: {
                'purchase_id': purchaseId,
                'reason': reason,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Refund Eligibility
     * @param purchaseId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static checkRefundEligibilityApiPaymentRefundEligibilityPurchaseIdGet(
        purchaseId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/refund-eligibility/{purchase_id}',
            path: {
                'purchase_id': purchaseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
