import { Container, Divider, Link, Typography, Button, Box } from '@mui/material';
import { useLocaleContext } from '../context/LocaleContext';

export default function RefundPolicy() {
  const { getLocaleLink } = useLocaleContext();
  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Refund Policy - Coding for Kids
      </Typography>
      <Typography variant="h6">Last Updated: January 27, 2025</Typography>
      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        1. Course Purchases
      </Typography>
      <Typography paragraph>
        Refunds are available for course purchases as long as the course has not been completed and
        no certificate has been issued. Refund requests for permanent purchases can be made within <strong>30 days</strong> of purchase. To request a refund for a course, please contact us via
        email or directly through your billing page.
      </Typography>
      <Box component="div" sx={{ my: 2 }}>
        <Button
          variant="contained"
          color="primary"
          href={getLocaleLink('/profile/billing')}
        >
          Go to Billing Page
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom>
        2. Subscription Plans
      </Typography>
      <Typography paragraph>
        You can cancel your subscription at any time. Refunds are available for the last payment made. Upon refund approval, your subscription will be canceled immediately.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Refund Process
      </Typography>
      <Typography paragraph>To request a refund:</Typography>
      <Typography component="ul" paragraph>
        <li>
          Visit your <Link href={getLocaleLink('/profile/billing')}>billing page</Link> if you are
          logged in.
        </li>
        <li>
          Alternatively, email us at{' '}
          <Link href="mailto:support@codingforkids.io">support@codingforkids.io</Link> with the
          following details:
          <Typography component="ul" paragraph>
            <li>Your full name</li>
            <li>Registered email address</li>
            <li>Purchase details (course name or subscription type)</li>
            <li>Reason for the refund request</li>
          </Typography>
        </li>
      </Typography>
      <Typography paragraph>
        Refund requests are typically reviewed within <strong>2 business days</strong>. Approved
        refunds will be issued to the original payment method and may take{' '}
        <strong>5–7 business days</strong> to process, depending on your payment provider.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Important Notes
      </Typography>
      <Typography paragraph>
        - Abuse of the refund policy (e.g., repeated refunds or misuse) may result in account
        suspension.
      </Typography>
      <Typography paragraph>
        - Refunds are not available for completed courses or certificates.
      </Typography>
      <Typography paragraph>
        - All subscription refunds result in immediate cancellation of the service.
      </Typography>
    </Container>
  );
}
