import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useSetUTMSource = () => {
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source');

  useEffect(() => {
    if (utmSource) {
      Cookies.set('utm_source', utmSource || '', { expires: 30 });
    }
  }, [utmSource]);

  return utmSource;
};

export default useSetUTMSource;
